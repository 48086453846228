import { useState } from "react";
import { amrPipelineDetailedActions } from "../../../../../actions";
import { OriginatingTransactionDocument } from "../../../../../types/amr-pipeline/models/OriginatingTransactionDocument";
import { TransactionAccessType } from '../../../../../types/amr-pipeline/enums/TransactionAccessType';
import { getSearchedTransactionDocuments, hasArrangerAccess } from "../../../../../utils/amr-pipeline.utils";
import { SearchInput } from "../../../../common";
import { DocumentSection } from "../../../common/documents/DocumentSection";
import { TabBody } from "../../../common/tabs/TabBody";
import { TransactionTabProps } from '../types';
import { withTabTracking } from '../withTabTracking';
import { RequiredFeature } from '../../../../access/RequiredFeature';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';
import { BlockedFeatureContent, SubscribeLink } from "../../../../../components/access/BlockedFeatureText";
import { useAppDispatch } from "../../../../../effects/useAppDispatch";

const DocumentsTab = ({ transaction, difference }: TransactionTabProps) => {
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');


    const documents = transaction.documents || [];
    const filteredDocuments = getSearchedTransactionDocuments(documents, searchTerm);

    function handleSearchTermChange(value: string) {
        setSearchTerm(value);
    }

    function handleSearchTermClear() {
        setSearchTerm('');
    }

    function sortDocuments(
        a: OriginatingTransactionDocument,
        b: OriginatingTransactionDocument
    ) {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    }

    return (
        <TabBody className="tabs-data-padding component-tabs-deal-documents">
            <RequiredFeature
                feature={SubscriptionFeature.IssuanceMonitorFullAccess}
                overrideRequiredFeatures={hasArrangerAccess(transaction)}
                renderBlockedContent={() => (
                    <BlockedFeatureContent
                        inline
                        className="empty-placeholder"
                        text={<><SubscribeLink /> to see Documents.</>}
                    />
                )}
            >
                <SearchInput
                    onClear={handleSearchTermClear}
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    placeholder="Search for documents"
                />
                <DocumentSection
                    title="All Documents"
                    transactionReferenceName={transaction.referenceName}
                    dealReferenceName={transaction.dealReferenceName}
                    emptyPlaceholder={searchTerm.length > 0 ? 'No search results.' : 'There are no documents yet.'}
                    items={filteredDocuments.sort(sortDocuments)}
                    difference={difference?.documents}
                    downloadAllAction={() => dispatch(amrPipelineDetailedActions.downloadTransactionDocuments(
                        transaction.dealReferenceName,
                        transaction.referenceName,
                        `${transaction.dealLegalName} Documents.zip`
                    ))}
                />
            </RequiredFeature>
        </TabBody>
    );
}

export const Documents = withTabTracking(TransactionAccessType.Documents)(DocumentsTab);
